.card {
  border: 1px solid rgba(0, 48, 135, 1);
  border-radius: 12px;
  box-shadow: 4px 4px 20px 0px rgba(62, 73, 84, 0.16);
  margin: 15px 20px 20px 20px;
}

.card-header {
  background-color: rgba(0, 48, 135, 0.05);
  border-bottom: 1px solid rgba(0, 48, 135, 1);
  border-radius: 12px;
  padding: 16px 30px;
  display: flex;
  align-items: center;
}

.card-content {
  padding: 16px 30px;
  overflow-x: auto;
  margin: 5px;
}

.chip-container {
  width: 80%;
}

.chip {
  font-size: 16px;
  font-weight: 900;
  line-height: 23px;
  height: 38px;
  background: rgba(0, 48, 135, 0.05);
  color: rgba(0, 48, 135, 1);
  border-radius: 12px;
}

.question-container {
  padding-top: 10px;
  display: flex;
  align-items: center;
}

.heading-container {
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.paperStyles {
  width: 450px;
  max-height: 250px;
  overflow: auto;
}
