.login-page {
  display: flex;
  height: 100vh;
  background-color: #fff;

  .left {
    position: relative;
    overflow: hidden;
    background-image: url("../../../assets/background-img.png");
    background-size: cover;
    background-position: center;
    flex: 0 0 35%;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 48, 135, 0.5);
    }

    .logo {
      position: absolute;
      top: 2rem;
      left: 2rem;
      width: 150px;
      height: auto;
    }

    .heading {
      position: absolute; 
      top: 8rem;
      left: 2rem;
      color: #fff;
    }

    .support-text {
      position: absolute;
      bottom: 4rem;
      left: 2rem;
      color: #fff;
    } 
  }

  .right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 2rem;
  }

  .login-form {
    align-self: flex-start;
    margin-left: 2rem;
    width: 60%;
  }

  .create-account {
    align-self: flex-end;
    margin-bottom: 2rem;
  }

  .copyright {
    width: 50%;
    margin-left: 2rem;
    margin-bottom: 2rem;
    align-self: flex-start;
    color: rgba(132, 129, 138, 1);
    span {
      color:rgba(0, 48, 135, 1);
    }
  }
}
