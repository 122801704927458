.app-layout {
  // display: grid;
  // grid-template-rows: auto 1fr auto;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  padding: 1rem;
}

.main-layout {
  display: flex;
  margin-top: 100px;
  height: calc(100vh - 145px);
  background-color: rgba(251, 251, 251, 1);
}

.MuiDrawer-paper {
  position: unset !important;
}
