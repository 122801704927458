.container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  margin: 0 20px;
}

.box {
  flex: 1;
  // padding: 10px;
}

.inner-box {
  flex: 1;
}

.box1 {
  flex: 1.3;
}

.box2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.inner-box1 {
  flex: 1.6;
  margin: 0 0 0 20px;
}

.inner-box2 {
  display: flex;
  justify-content: end;
  align-items: center;
}

.divider {
  margin: 0 20px;
}

.title {
  display: flex;
}

@media print {
  .hideBtn {
    display: none;
  }
}

.panelModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #003087;
}
.panelDiv {
  background-color: #b6c3db;
  border-radius: 10px;
  padding: 12px;
  text-align: center;
}
.panelForm {
  width: 94vw;
  max-height: 90vh;
  overflow-y: auto;
  padding: 15px;
}
