.header-logo {
    height: 95px;
}

.appBar {
    background-color: #FBFBFB;
}

.navbar {
    height: 100px;
}

.headerIcon {
    padding-right: 10px;
}